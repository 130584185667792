import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios";

export default function allotoutbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'select', label: '#'},
    //{ key: 'id', label: 'ID'},
    // { key: 'outbound_id', label: '出库单ID', sortable: true },
    // { key: 'outbound_no', label: '出库单编号', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'name', label: '名称'},
    // { key: 'stocklock_id', label: '', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    // { key: 'category_id', label: '分类ID', sortable: true },
    // { key: 'category_name', label: '分类名称', sortable: true },
    // { key: 'brand_id', label: '品牌ID', sortable: true },
    // { key: 'brand_name', label: '品牌名称', sortable: true },
    { key: 'warestatus', label: '库存状态'},
    { key: 'stockcost', label: '成本'},
    { key: 'num', label: '库存数量'},
    { key: 'qty', label: '计划调拨数量'},
    { key: 'trueqty', label: '实际调拨库数量'},
    { key: 'is_receive', label: '对方仓库是否已接收'},
    { key: 'allotbound_time', label: '调拨时间'},
    { key: 'allotbound_creator', label: '调拨人'},
    { key: 'receipt_time', label: '回执时间'},
    { key: 'receipt_creator', label: '回执人'},
    // { key: 'receiveqty', label: '对方实收数量', sortable: true },
    // { key: 'returnqty_good', label: '对方退货数量(良品)  ', sortable: true },
    // { key: 'returnqty_bad', label: '对方退货数量(不良品)  ', sortable: true },
    // { key: 'status', label: '出库状态 allot_outbound_status', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(100)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('outbounditem_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (config.readonly!==0){
      for (let i = 0; i < tableColumns.length; i++) {
        if (tableColumns[i].key=="actions"){
          tableColumns.splice(i,1)
        }
      }
    }
    store
      .dispatch('allotoutbounditem/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        outbound_id:config.outbound_id,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const warehouselist = (ctx, callback) => {
    axios
        .post("/api/allotoutbounditem/warehouselist", {
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          id:config.outbound_id,
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals

          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    warehouselist,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
