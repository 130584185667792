import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCode, getCodeLabel, getCodeOptions, toDate} from "@core/utils/filter";

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'warehouse_id', label: '仓库'},
    { key: 'company_id', label: '货主'},
    { key: 'name', label: '商品名称'},
    { key: 'code', label: '69码'},
    { key: 'is_gift', label: '是否赠品', sortable: true },
    { key: 'qty', label: '库存数量'},
    { key: 'outboundTrueQty', label: '待出库数量'},
    { key: 'useQty', label: '本次使用数量'},
    // { key: 'productcode', label: '69码', },
    // { key: 'productunit', label: '单位' },
    // { key: 'boxquantity', label: '单箱数量', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    // { key: 'stock_no', label: '编号', sortable: true },
    // { key: 'location_fullname', label: '库位', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'batch_no', label: '批次', sortable: true },
    // { key: 'is_gift', label: '赠品', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    // { key: 'qty_lock', label: '锁库数量', sortable: true },
    // { key: 'product_cost', label: '商品成本', sortable: true },
    // { key: 'tax_rate', label: '税率', sortable: true },
    // { key: 'cost', label: '最终成本', sortable: true },
    { key: 'status', label: '状态'},
    // { key: 'add_time', label: '时间', sortable: true },
    // { key: 'creator', label: '入库人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref('stock_id')
  const isSortDirDesc = ref(true)
  //getCode("companyStock",61)

  const company_id = ref(getCode("companyStock"))
  const productId = ref('')
  const status = ref(getCode("stock_status",0))
  const isReduceStock = ref(1)
  const orderByOption = ref({"label":"过期日期","code":"expiry_date"})
  const qty = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchALlList = (ctx, callback) => {
    if (orderByOption.value!=""&&orderByOption.value!=null){
      orderBy.value=orderByOption.value.code
    }else {
      orderBy.value="stock_id"
    }
    store
      .dispatch('stock/searchAllInfo', {
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === false ? 'desc':'',
        company_id:company_id.value==null?null:company_id.value.value,
        warehouseid:config.warehouseId,
        product_id:productId.value,
        status:status.value==null?null:status.value.value,
        isReduceStock:isReduceStock.value,
        useQty:qty.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        let count = 0
        for (let i = 0; i < list.length; i++) {
          if (list[i].ext.useQty>0){
            list[i].isSelect=1
          }else {
            list[i].isSelect=0
          }
          count+=list[i].ext.useQty
        }
        if (count<qty.value){
          toast.error("当前可用库存数量不足!")
        }
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    searchALlList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    company_id,productId,status,isReduceStock,orderByOption,qty,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    // Extra Filters
  }
}
