<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end" v-if="readonly===0">
              <b-button
                      variant="info"
                      @click="showselproduct()"
                      class="mr-1"
                      v-if="getCodeLabel('setting','start_inventory')!=='1'"
              >
                <span class="text-nowrap">人工选择</span>

              </b-button>

              <b-button
                      variant="primary"
                      @click="showAllotoutboundStock()"
              >
                <span class="text-nowrap">自动选批次</span>

              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="datalist"
      >

        <!-- Columns -->
        <template #cell(name)="data">
          [#{{ data.item.id }}]{{ data.item.name }}
        </template>

        <template #cell(batch_no)="data">
          批次：{{data.item.batch_no}}
          <br>
          开始：{{toTime(data.item.production_date)}}
          <br>
          结束：{{toTime(data.item.expiry_date)}}
        </template>

        <template #cell(warestatus)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('stock_status', data.item.warestatus)}`"
          >
            {{getCodeLabel('stock_status', data.item.warestatus)}}
          </b-badge>
        </template>

        <template #cell(stockcost)="data">
          {{data.item.ext.stockcost}}
        </template>

        <template #cell(is_receive)="data">
          {{getCodeLabel('yesno', data.item.is_receive)}}
        </template>

        <template #cell(allotbound_time)="data">
          {{toTime(data.item.allotbound_time)}}
        </template>

        <template #cell(receipt_time)="data">
          {{toTime(data.item.receipt_time)}}
        </template>

        <template #cell(allotbound_creator)="data">
          {{getCodeLabel('user', data.item.allotbound_creator)}}
        </template>

        <template #cell(receipt_creator)="data">
          {{getCodeLabel('user', data.item.receipt_creator)}}
        </template>

        <template #cell(num)="data">
          <span v-html="data.item.ext.mystock"></span>
        </template>

        <template #cell(qty)="data">

          <b-form-input
                  id="memo"
                  size="sm"
                  v-model="data.item.qty"
                  @change="checkQty(data.item)"
                  type="number"
                  :readonly="isNumber(readonly)===1"
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
                  @click="changeState(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-${getCodeColor('state', data.item.state)}`"

            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!--          <b-link @click="save(data.item)">
                      <feather-icon
                          icon="SaveIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </b-link>-->
          <b-link>
            <feather-icon @click="delOrderItem(data.item)"
                          icon="XSquareIcon"
                          size="16"
                          class="align-middle text-body"
            />
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>


      <b-modal
              id="stockModal"
              ok-only
              ok-title="确认"
              @ok="onSelectStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
      >
        <stock-list
                ref="stockSelect" :warehouseid="warehouseid">
        </stock-list>
      </b-modal>

      <b-modal
              id="allotoutbound-stock"
              ok-only
              ok-title="确认"
              @ok="onSelectAllotoutboundStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
      >
        <stock-manage
                ref="allotoutboundStockSelect" :warehouseid="warehouseid">
        </stock-manage>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber } from '@core/utils/filter'
  import allotoutbounditemUseList from './allotoutbounditemUseList'
  import allotoutbounditemStore from './allotoutbounditemStore'
  import stockList from '@/views/apps/stock/StockList'
  import { useToast } from 'vue-toastification/composition'
  import StockAllotOutboundItemEdit from "@/views/apps/stockallotoutbounditem/StockAllotOutboundItemEdit";
  import StockManage from "@/views/apps/stock/allotoutbound-stock/StockManage";
  import axios from "@/libs/axios";

  export default {

    components: {
      StockManage,
      StockAllotOutboundItemEdit,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,

      stockList,
    },
    data(){
      return{
        datalist:[],
        warehouseid:0,
      }
    },
    props:{
      outbound_id :{
        type : Number,
        default : 0
      },
      warehouseidtemp:{
        type: Number,
        default:0
      },
      readonly:{
        type: Number,
        default:0,
      }
    },
    methods: {
      changeState: function(data) {
        const state = 1 - data.state
        store.dispatch('allotoutbounditem/state', {id: data.id, state: state}).then(res => {
          data.state = state
        })
      },
    },
    setup(props) {
      const toast = useToast()
      // Register module
      if (!store.hasModule('allotoutbounditem')) store.registerModule('allotoutbounditem', allotoutbounditemStore)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('allotoutbounditem')) store.unregisterModule('allotoutbounditem')
      })

      const showselproduct = function(){
        if(props.warehouseidtemp == 0){
          toast.error("请选择调出仓库")
          return false
        }
        this.$bvModal.show('stockModal')
        this.warehouseid = props.warehouseidtemp
      }

      const showAllotoutboundStock = function(){
        if(props.warehouseidtemp == 0){
          toast.error("请选择调出仓库")
          return false
        }
        this.$bvModal.show('allotoutbound-stock')
        this.warehouseid = props.warehouseidtemp
      }



      const onSelectStock = function (){
        let returnData = {}

        if(this.$refs.stockSelect.getChecked().length > 0){
          let stockids = "";
          let stocklockids = "";
          for(let i=0;i<this.$refs.stockSelect.getChecked().length;i++){
            returnData = this.$refs.stockSelect.getChecked()[i]
            if(returnData.stocklock_id == undefined){
              returnData.stocklock_id = 0
            }
            if(stockids === ""){
              stockids = returnData.stock_id
            }else{
              stockids += "," + returnData.stock_id
            }

            if(stocklockids === ""){
              stocklockids = returnData.stocklock_id
            }else{
              stocklockids += "," + returnData.stocklock_id
            }

          }

          store.dispatch('allotoutbounditem/addnewpro', {outboundid: this.outbound_id, stockids: stockids,stocklockids: stocklockids}).then(res => {
            if(res.data.code == 0){
              refetchData()
            }else{
              toast.error(res.data.data)
            }
          })

        }
      }

      const onSelectAllotoutboundStock = function (){
        let returnData = {}
        // returnData = this.$refs.allotoutboundStockSelect.getChecked()
        // console.log(returnData)
        if(this.$refs.allotoutboundStockSelect.getChecked().length > 0){
          let stockids = "";
          let stocklockids = "";
          let qty = "";
          for(let i=0;i<this.$refs.allotoutboundStockSelect.getChecked().length;i++){
            returnData = this.$refs.allotoutboundStockSelect.getChecked()[i]
            if(returnData.stocklock_id == undefined){
              returnData.stocklock_id = 0
            }
            if(stockids === ""){
              stockids = returnData.stock_id
            }else{
              stockids += "," + returnData.stock_id
            }

            if(stocklockids === ""){
              stocklockids = returnData.stocklock_id
            }else{
              stocklockids += "," + returnData.stocklock_id
            }

            if(qty === ""){
              qty = returnData.ext.useQty
            }else{
              qty += "," + returnData.ext.useQty
            }
          }

          store.dispatch('allotoutbounditem/addnewpro', {outboundid: this.outbound_id, stockids: stockids,stocklockids: stocklockids,qty:qty}).then(res => {
            if(res.data.code == 0){
              refetchData()
            }else{
              toast.error(res.data.data)
            }
          })

        }
      }

      const checkQty = function(item){
        //查询可用库存
        axios.post('api/allotoutbound/getProductQtyFromOneWarehouse', {productId: item.product_id, warehouse_id: props.warehouseidtemp}).then(res => {
          let  canUseQty =  0
          if(res.data.code === 0){
            canUseQty = res.data.data.ext.canUseQty
            if (isNumber(item.qty) > 0) {
              const cRegExp = /^[0-9]\d*$/
              if (!cRegExp.test(item.qty)) {
                toast.error('调拨数量必须是正整数!')
                item.qty = 0
              } else if(item.qty > canUseQty) {
                toast.error("调拨数量不可大于可用库存!")
                refetchData()
              } else {
                store.dispatch('allotoutbounditem/updateOrderItem', {itemid: item.outbounditem_id, qty: item.qty}).then(res => {
                  if(res.data.code === 0){
                    toast.success("商品"+item.name+"保存成功")
                    refetchData()
                  }else{
                    toast.error("商品"+item.name+"保存失败 "+res.data.data)
                    refetchData()
                  }
                })
              }
            } else {
              toast.error('调拨数量填写错误！')
              item.qty = 0
            }
          }
        })
      }


      const delOrderItem = function(item) {
        if(confirm("确认删除吗？")){
          store.dispatch('allotoutbounditem/delOrderItem', {id: item.outbounditem_id}).then(res => {
            if (res.data.code == 0) {
              toast.success("删除成功")
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }

      const save = function (params) {
        store.dispatch('allotoutbounditem/updateOrderItem', {itemid: params.outbounditem_id, qty: params.qty}).then(res => {
          if(res.data.code === 0){
            toast.success("商品"+params.name+"保存成功")
            refetchData()
          }else{
            toast.error("商品"+params.name+"保存失败 "+res.data.data)
            refetchData()
          }
        })
      }

      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // UI
      } = allotoutbounditemUseList({
        outbound_id:props.outbound_id,
        readonly: props.readonly
      })

      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        // UI
        toTime,

        onSelectStock,
        onSelectAllotoutboundStock,
        checkQty,
        delOrderItem,
        showselproduct,
        showAllotoutboundStock,
        save,
        isNumber
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
