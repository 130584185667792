import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbounditem/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbounditem/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbounditem/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbounditem/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/getStock', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    addnewpro(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/addnewpro', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    delOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/delOrderItem', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    warehouselist(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/warehouselist', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getqty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/getqty', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    outbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/outbound', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    outboundAll(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/outboundAll', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    receiveitem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/receiveitem', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/updateOrderItem', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    receiveitemnoreturn(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbounditem/receiveitemnoreturn', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
